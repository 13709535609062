<template>
  <div class="main">
    <el-row :gutter="20">
      <el-col :span="4">
        <div class="side">
          <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo overflow"
            @open="handleOpen"
            @close="handleClose"
            :router="true"
            :unique-opened="true"
            text-color="#333"
          >
            <el-menu-item index="/protocol/Introduction/">
              <span slot="title">网站简介</span>
            </el-menu-item>
            <el-menu-item index="/protocol/aboutUs/">
              <span slot="title">联系我们</span>
            </el-menu-item>
            <el-menu-item index="/protocol/userServe/">
              <span slot="title">用户服务协议</span>
            </el-menu-item>
            <el-menu-item index="/protocol/agreement/">
              <span slot="title">隐私政策</span>
            </el-menu-item>
            <el-menu-item index="/protocol/law/">
              <span slot="title">法律声明</span>
            </el-menu-item>
<!--            <el-menu-item index="/protocol/barrierFree/">-->
<!--              <span slot="title">无障碍声明</span>-->
<!--            </el-menu-item>-->
          </el-menu>
        </div>
      </el-col>
      <el-col :span="20" class="content">
        <div class="ast">
        <router-view />

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "center",
  data() {
    return {
      active: "1",
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/public.less";
.main {
  width: 1200px;
  margin: 10px auto;
}
.ast{
  padding: 20px;
}
.content {
  background: white;
  box-sizing: border-box;
}
.el-menu-item:after {
  transition: none;
}
.el-menu-item.is-active {
  color: #333333;
}
.el-menu {
  border-right: none;
}
</style>
